import React from "react";
import { Link, } from "react-router-dom";
import PNav from "../components/PNav"
import Sidebar from "../components/Sidebar";

const Print1 = () => {
  return (
    <div>

    <div >
      
      <PNav />


      <div>

      <div>
        <Sidebar/>
      </div>

      </div>

    </div>
  </div>
  );
};

export default Print1

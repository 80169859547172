import React from 'react'
import PageHeader from '../components/PageHeader'
import PageContent from '../components/PageContent'
 
function VisionPage() {
  return (
    <>
    <PageHeader title="About Us" />
    <PageContent elem={<div>
        <h2 style={{textAlign:'left'}}>
        Vision
        </h2>
        <p style={{fontSize:'16px'}}>
        To bring excellence in imparting higher education to the rural youth and equip them to participate in the nation building activity with a view to promote their individual, social and national growth
        </p>

        <h2 style={{textAlign:'left'}}>
        Mission
        </h2>

        <p style={{fontSize:'16px'}}>
To serve the rural youth by providing them with easy access to higher education and job opportunities
</p>
<p style={{fontSize:'16px'}}>
To stimulate intellectual, moral, cultural values of rural students for holistic development.
</p>
<p style={{fontSize:'16px'}}>
To inculcate the spirit of inquisitiveness towards research and innovation.
</p>
<p style={{fontSize:'16px'}}>
To instill in them the spirit of patriotism, dedication & humanistic values
</p>
    </div>}  />
    </>
    
  )
}

export default VisionPage
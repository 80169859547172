import React, { useState } from 'react';
const Registrationform = () => {
const [formData, setFormData] = useState({
candidateName: '',
fatherName: '',
motherName: '',
gender: '',
dob: '',
mobileNo: '',
email: '',
aadharNo: '',
reenterAadharNo: '',
passedCourseName: '',
subject: '',
yearsStudied: '',
courseApplied: '',
reservationCategory: '',
percentage: '',
graduationMarksheet: null,
photo: null,
aadharCard: null
});
const handleChange = (e) => {
const { name, value, files } = e.target;
setFormData(prevState => ({
...prevState,
[name]: files ? files[0] : value
}));
};
const handleSubmit = (e) => {
e.preventDefault();
console.log(formData);
};

return (
<div>
    
    <div className="form-container">
    <p className="Info-reg">
            परास्नातक में प्रवेश हेतु विषय चयन से सम्बंधित नियमों तथा न्यूनतम
            प्रतिशत से सम्बंधित नियमों को देखने हेतु यहाँ
            <span className="info-link">क्लिक करें</span>
        </p>
        <form onSubmit={handleSubmit} className='form-reg'>
            <label>
                Select Course / (पाठ्यक्रम जिसे करना चाहते हैं) :
                <select name="gender" value={formData.gender}
                    onChange={handleChange}>
                    <option value="">--Select--</option>
                    <option value="Male">BA</option>
                    <option value="Female">BSC</option>
                    <option value="Other">MA</option>
                    <option value="Other">BCA</option>
                    <option value="Other">MCA</option>
                </select>
            </label>
        
            <div className="form-grid">
                <div className="form-column">
                    <label className="full-width">
                        Candidate's Name / (अभ्यर्थी का नाम) : *
                        <input type="text" name="candidateName"
                            value={formData.candidateName}
                            onChange={handleChange} required />
                    </label>
                    <label>
                        Gender / (लिंग) :
                        <select name="gender" value={formData.gender}
                            onChange={handleChange}>
                            <option value="">--Select--</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                        </select>
                    </label>
                    <label>
                        E-mail / (ईमेल) : *
                        <input type="email" name="email" value={formData.email}
                            onChange={handleChange} required />
                    </label>
                    <label>
                        Passed Course Name / (उत्तीर्ण पाठ्यक्रम का नाम) :
                        <select name="passedCourseName"
                            value={formData.passedCourseName}
                            onChange={handleChange} required>
                            <option value="">--Select--</option>
                            <option value="Course1">Course 1</option>
                            <option value="Course2">Course 2</option>
                        </select>
                    </label>
                    <label>
                        Course Applied / (पाठ्यक्रम जिसके लिए आवेदन करना हैं) :
                        <input type="text" name="courseApplied"
                            value={formData.courseApplied}
                            onChange={handleChange} required />
                    </label>
                    <label>
                        Graduation / Intermediate Marksheet : *
                        <input className='input-reg' type="file" name="graduationMarksheet"
                            onChange={handleChange} />
                    </label>
                </div>
                <div className="form-column">
                    <label>
                        Father's Name / (पिता का नाम) : *
                        <input type="text" name="fatherName"
                            value={formData.fatherName} onChange={handleChange}
                            required />
                    </label>
                    <label>
                        Date of Birth / (जन्मतिथि) : *
                        <input type="date" name="dob" value={formData.dob}
                            onChange={handleChange} required />
                    </label>
                    <label>
                        AADHAR No. / (आधार नं.) *
                        <input type="text" name="aadharNo"
                            value={formData.aadharNo} onChange={handleChange}
                            required />
                    </label>
                    <label>
                        Choose Subject/(किस विषय से करना चाहते हैं?) :
                        <select name="subject" value={formData.subject}
                            onChange={handleChange}>
                            <option value="">--Select--</option>
                            <option value="Subject1">Subject 1</option>
                            <option value="Subject2">Subject 2</option>
                        </select>
                    </label>
                    <label>
                        Reservation Category / (आरक्षण श्रेणी) : *
                        <select name="reservationCategory"
                            value={formData.reservationCategory}
                            onChange={handleChange} required>
                            <option value="">Please select appropriate category
                            </option>
                            <option value="General">General</option>
                            <option value="OBC">OBC</option>
                            <option value="SC">SC</option>
                            <option value="ST">ST</option>
                        </select>
                    </label>
                    <label>
                        Photo : *
                        <input className='input-reg' type="file" name="photo"
                            onChange={handleChange} />
                    </label>
                </div>
                <div className="form-column">
                    <label>
                        Mother's Name / (माता का नाम) : *
                        <input type="text" name="motherName"
                            value={formData.motherName} onChange={handleChange}
                            required />
                    </label>
                    <label>
                        Mobile No. / (मोबाइल नं.) : *
                        <input type="tel" name="mobileNo"
                            value={formData.mobileNo} onChange={handleChange}
                            required />
                    </label>
                    <label>
                        Re-enter AADHAR No. / (आधार नं.पुनः भरें) : *
                        <input type="text" name="reenterAadharNo"
                            value={formData.reenterAadharNo}
                            onChange={handleChange} required />
                    </label>
                    <label>
                        पूर्व कक्षा में यह विषय कितने वर्ष अध्ययन किया है ? :
                        <select name="yearsStudied"
                            value={formData.yearsStudied}
                            onChange={handleChange}>
                            <option value="">Please Select Year</option>
                            <option value="1">1 Year</option>
                            <option value="2">2 Years</option>
                            <option value="3">3 Years</option>
                        </select>
                    </label>
                    <label>
                        Percentage in Passed Exam / (उत्तीर्ण पाठ्यक्रम में
                        प्रतिशत) :
                        <input type="number" name="percentage"
                            value={formData.percentage} onChange={handleChange}
                            required />
                    </label>
                    <label>
                        Aadhar Card : *
                        < input className='input-reg' type="file" name="aadharCard"
                            onChange={handleChange} />
                    </label>
                </div>
            </div>
            <button className='Reg-button' type="submit" onClick={handleSubmit}>Submit</button>
        </form>
    </div>
</div>
);
};
export default Registrationform;
import React from "react";
import PNav from "../components/PNav"
import Sidebar from "../components/Sidebar"
import { Link } from "react-router-dom";


const Option = () => {
  return (
    <div>

    <div >
      
      <PNav />


      <div>

      <div>
        <Sidebar/>
      </div>

      </div>

    </div>
  </div>
  )
}

export default Option
